import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import  galleryImagesReducer  from "./galleryImagesReducer";
import emailSlice from "./emailSlice";
import configSlice from "./configSlice";
import sliderSlice from "./sliderSlice";
import authSlice from "./authSlice";

const rootReducer = combineReducers({
  galleryImage: galleryImagesReducer,
  email: emailSlice,
  config: configSlice, 
  slider: sliderSlice,
  auth: authSlice 
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

import "./CorporateEvents.scss";
import EventCardInfo from "./EventCardInfo";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function CorporateEvents() {
  const { t } = useTranslation();
  const obj = [
    {
      isActive: true,
      url: "/images/Corporate Events/1.png",
      icon_name: "icon-corporate-holiday-parties",
      title: t("Corporate Holiday Parties"),
      card_title: "Corporate Holiday Parties",
      description: t(`Every holiday is cause for celebration at Grand Banquet Hall, and we’re ready to help your company celebrate in style. With a host of delectable catering options, stylish décor, and classy accommodations, our event venue is an excellent location for any corporate holiday party.`),
    },
    {
      isActive: false,
      url: "/images/Corporate Events/3.png",
      icon_name: "icon-awards-banquets",
      title: t("Awards Banquets"),
      card_title: "Awards  Banquets",
      description: t(`Your colleagues deserve to be recognized for their accomplishments, and what better way to celebrate their work than with an awards banquet at a lavish corporate event venue? Our staff will work with you to ensure all your corporate awards ceremony needs are met, bringing your company and its hardworking staff the recognition they rightfully deserve.`),
    },
    {
      isActive: false,
      url: "/images/Corporate Events/4.png",
      icon_name: "icon-retirement-parties",
      title: t("Retirement Parties"),
      card_title: "Retirement  Parties",
      description: t(`Loyal employees deserve a proper recognition when they decide to retire, and a retirement party at Grand Banquet Hall will definitely provide a proper send-off. Our corporate event venue can accommodate the bar and catering services expected at a retirement party, and offers a wealth of customizable amenities to make the celebration truly memorable.`),
    },
    {
      isActive: false,
      url: "/images/Corporate Events/5.png",
      icon_name: "icon-corporate-conferences",
      title: t("Corporate Conferences"),
      card_title: `Corporate  Conferences`,
      description: t(`If you’re holding a corporate conference or presentation, you’ll need an event venue with a sophisticated, professional feel. Grand Banquet Hall’s exquisite layout and polished event staff will help you command respect from employees and clients alike, providing the atmosphere you need for a successful corporate event. We even have a multimedia projector, screen, and stage available for important presentations.`),
    },
  ];

  const [selectedCard, setCard] = useState(obj[0]);
  return (
    <div className="CorporateEvents">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Corporate Events/Corporate Events 1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <p className="site-name">
            {t("Professional")} <br /> {t("Corporate Event Venue")}
          </p>
        </div>
      </div>

      <div className="container page-info">
        <p>
          {t(
            "Your corporate functions deserve an event venue that lives up to your company’s sterling reputation. Grand Banquet Hall’s superior service, versatile venue, and client-first mentality have helped us earn a reputation as one of the best corporate event venues in Los Angeles."
          )}
          <br />
          <br />
          {t(
            "Our corporate venue can hold up to 300 guests, with exceptional décor and a wealth of customizable amenities at your disposal, making it the perfect space for corporate conferences, holiday parties, awards banquets, and so much more."
          )}
        </p>
      </div>
      <div className="full-block-image">
        <img alt="" src="/images/Corporate Events/Corporate Events 2.png" />
      </div>
      <div className="container categories">
        <div className="row flex-nowrap">
          {obj.map((card, index) => {
            return (
              <div
                key={index}
                className={
                  card.title === selectedCard.title ? "box active" : "box"
                }
                onClick={() => setCard(card)}
              >
                <i className={card.icon_name}></i>
                <p>{card.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <EventCardInfo card={selectedCard} />

      <div className="container-fluid superior-catering">
        <div className="row">
          <div className="col-0 col-md-4 col-xl-6 img-box d-none d-md-block">
            <img alt="" src="/images/Corporate Events/Corporate Events 3.png" />
          </div>
          <div className="col-12 col-md-8 col-xl-6 content">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Superior Catering")} <br /> {t("Services For Weddings")}
              </h2>
              <p className="info">
                {t(
                  "Your guests deserve nothing but the best catering at your wedding reception, and this is why we at Grand Banquet Hall work with the best in the industry. We are proud to offer phenomenal wedding catering services. We’ll work with you to create the perfect wedding reception menu, with signature family style options, hors d’oeuvres plates, and buffet dining. Our catering is guaranteed to impress and delight your guests."
                )}
              </p>
            </div>
            <div>
              <span className="btn btn-transparent btn-shadow">
                {t("Dining & Catering Options")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container enjoy-an-exceptional">
        <div className="mini-line"></div>
        <h2 className="big-title">
          {t("Enjoy an Exceptional Corporate Event Venue!")}
        </h2>
        <p>
          {t(
            "Grand Banquet Hall’s superior accommodations, amenities, and service truly make it one of the best corporate event venues in Los Angeles. For more information on our corporate event venue services, contact Grand Banquet Hall today."
          )}
        </p>
      </div>
    </div>
  );
}

export default CorporateEvents;


import { useState } from "react";
import "./BookNowForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailQuery } from "../../../reducers/emailSlice";

function BookNowForm() {
  const dispatch = useDispatch();
  const { eventsList, isLoad } = useSelector((state) => state.email);

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    guests_count: "",
    phone: "",
    email: "",
    message: "",
    date: "",
    event_name: null,
  });

  const [active, setActive] = useState(false);

  const onChangeHendler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const sendEmail = (event) => {
    if (document.querySelector(".form-contact-us").checkValidity()) {
      event.preventDefault();
      dispatch(sendEmailQuery(form)).then(() => {
        console.log('gnac oka sax')
      })
    }
  };

  return (
    <div
      className="BookNow"
      style={{ transform: active ? "translateX(0)" : "translateX(-100%)" }}
    >
      <div className="container">
        <div className="mini-line"></div>
        <h2>Book Now</h2>
        <p>
          Start selecting your desire date by filling out this simple booking
          inquiry form and be one step closer to having <br /> your special
          event at the beautiful Grand Banquet Hall.
        </p>
        <form action="#" className="form-contact-us">
          <div className="row">
            <div className="col-6">
              <input
                required
                type="text"
                name="first_name"
                placeholder="First name*"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
              />
            </div>
            <div className="col-6">
              <input
                required
                type="text"
                name="last_name"
                placeholder="Last name*"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input
                required
                type="text"
                name="phone"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
                placeholder="Phone*"
              />
            </div>
            <div className="col-6">
              <input
                required
                type="email"
                name="email"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
                placeholder="Email*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="select-box">
                <select
                  required
                  name="event_name"
                  onChange={(event) => {
                    onChangeHendler(event);
                  }}
                >
                  <option>Please select your event*</option>
                  {eventsList.map((list, index) => (
                    <option key={index + 1}>{list}</option>
                  ))}
                </select>
                <i>&#8964;</i>
              </div>
            </div>
            <div className="col-6 date-container">
              <div className="select-box">
                <input
                  required
                  type="number"
                  name="guests_count"
                  onChange={(event) => {
                    onChangeHendler(event);
                  }}
                  placeholder="Estimated Number of Guests*"
                />
              </div>
              <input
                required
                type="date"
                className="date"
                name="date"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <textarea
                required
                name="message"
                onChange={(event) => {
                  onChangeHendler(event);
                }}
                placeholder="Message*"
              ></textarea>
            </div>
          </div>
          <button
            onClick={sendEmail}
            disabled={isLoad}
            className="btn btn-transparent btn-shadow dark"
          >
            {isLoad ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
      <div
        className={active ? "active" : ""}
        className="btn-book"
        onClick={() => setActive(!active)}
      >
        <span className="title">BOOK NOW</span>
        {active ? (
          <span className="arrow">&#x2039;</span>
        ) : (
          <span className="arrow">&#x203A;</span>
        )}
      </div>
    </div>
  );
}

export default BookNowForm;

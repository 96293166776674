import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getGalleryImages = createAsyncThunk("images", async () => {
  return await axios.get("https://api.grandbanquetla.com/gallery-images");
});

export const uploadImage = createAsyncThunk("upload", async (formData) => {
  return await axios.post(
    "https://api.grandbanquetla.com/upload-gallery-image",
    formData
  );
});

export const removeImageQuery = createAsyncThunk("remove", async (id) => {
  return await axios.delete(`https://api.grandbanquetla.com/gallery-image/${id}`);
});

export const galleryImagesReducer = createSlice({
  name: "galleryImages",
  initialState: {
    images: null,
    currentPage: 1,
    limit: 16,
    pageCount: null,
    isLoad: false,
    isRemov: false,
  },
  reducers: {
    updateCurrentPage: (state, action) => {
      return { ...state, currentPage: action.payload };
    },
  },

  extraReducers: {
    [getGalleryImages.pending]: (state, action) => {
      return { ...state, isLoad: true };
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      const totalPageCount = parseInt(action.payload.data.length / 16) + 1;
      return {
        ...state,
        images: [...action.payload.data],
        isLoad: false,
        pageCount: totalPageCount,
      };
    },

    [removeImageQuery.pending]: (state, action) => {
      return { ...state, isRemov: true };
    },
    [removeImageQuery.fulfilled]: (state, action) => {
      const filterArr = state.images.filter(
        (item) => item._id !== action.meta.arg
      );
      return {
        ...state,
        images: filterArr,
        isRemov: false,
      };
    },

    [uploadImage.pending]: (state, action) => {
      return { ...state, isLoad: true };
    },
    [uploadImage.fulfilled]: (state, action) => {
      const totalPageCount = parseInt(action.payload.data.length / 16) + 1;

      return {
        ...state,
        images: [...action.payload.data],
        isLoad: false,
        pageCount: totalPageCount,
      };
    },
    [getGalleryImages.rejected]: (state, action) => {},
  },
});

export const { updateCurrentPage } = galleryImagesReducer.actions;

export default galleryImagesReducer.reducer;

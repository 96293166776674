import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CateringCard({ card }) {
  const { t } = useTranslation();
  return (
    <div className="container event-info">
      <div className="row">
        <div className="col-6 img-block">
          <img alt="" src={card.url} />
        </div>
        <div className="col-md-12 col-sm-12 col-lg-6 content">
          <div>
            <span className="mini-line"></span>
            <h2 className="big-title">{card.title}</h2>
            <p>{card.description}</p>
          </div>
          <div>
            <Link to="contact">
              <span className="btn btn-transparent btn-shadow">
                {t("Contact us for catering")}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CateringCard;


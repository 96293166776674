import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Birthday.scss";

function Birthday() {
  const { t } = useTranslation();

  return (
    <div className="Birthday">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Birthday page/Birthday1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <p className="site-name">
            {t("Birthday Parties")} <br /> {t("& Quinceañeras")}
          </p>
        </div>
      </div>

      <div className="container page-info">
        <p>
          {t(
            "At Grand Banquet Hall, we are firm believers in celebrating life—and what better way to celebrate life than by throwing an iconic birthday party? Our event professionals have worked relentlessly over the years to establish a reputation as one of the best birthday party and quinceañera venues in Los Angeles."
          )}
          <br />
          <br />
          {t(
            "What sets us apart from other banquet halls is our determined strategy for making your birthday special. We are willing to customize the space, menu, and services to your liking in order for you and your guests to feel like true royalty on your big night. Indulge yourself in our delicious food, enjoy the view of our elegant décor, then dance the night away on our dazzling dance floor! A birthday party or quinceañera at Grand Banquet Hall is truly an event to remember forever."
          )}
        </p>
      </div>

      <div className="container stunning-party-hall">
        <div className="row">
          <div className="col-lg-6 info">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Stunning")} <br /> {t("Quinceañera Party Hall")}
              </h2>
              <p>
                {t(
                  "A quinceañera signals your daughter’s transition from childhood to womanhood. It’s a day she’ll remember for the rest of her life, and the experts at Grand Banquet Hall will help you make her special day a big success."
                )}
                <br />
                <br />
                {t(
                  "Our 300-guest venue can accommodate both intimate and large quinceañeras, allowing you to invite as many friends and family members as you want. And with a customizable venue layout, including a stage, you’ll be able to set up the venue for traditional elements such as the candle-lighting ceremony. And as everyone knows, dancing is a huge part of any quinceañera. We’re proud to feature a spacious dance floor for all the special dances that a quinceañera requires!"
                )}
              </p>
            </div>
            <div>
              <Link to="venue">
                <span className="btn btn-shadow dark">
                  {t("explore the venue")}
                </span>
              </Link>
              <Link to="gallery">
                <span className="btn btn-shadow dark btn-transparent">
                  {t("photo gallery")}
                </span>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 d-none d-md-none d-lg-block img-block">
            <img alt="" src="/images/Birthday page/Birthday2.png" />
          </div>
        </div>
      </div>

      <div className="container-fluid superior-catering">
        <div className="row">
          <div className="col-lg-6 d-none d-md-none d-lg-block img-box">
            <img alt="" src="/images/Birthday page/Birthday3.png" />
          </div>
          <div className="col-lg-6 content">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("photo gallery")} <br /> {t("Services For your")} <br />{" "}
                {t("birthday")}
              </h2>
              <p className="info">
                {t(
                  "Your guests deserve nothing but the best catering at your wedding reception, and this is why we at Grand Banquet Hall work with the best in the industry. We are proud to offer phenomenal wedding catering services. We’ll work with you to create the perfect wedding reception menu, with signature family style options, hors d’oeuvres plates, and buffet dining. Our catering is guaranteed to impress and delight your guests."
                )}
              </p>
            </div>
            <div>
              <span className="btn btn-transparent btn-shadow">
                {t("Dining & Catering Options")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container enjoy-an-exceptional">
        <div className="mini-line"></div>
        <h2 className="big-title">
          {t("Exceptional Service And Customizable Amenities")}
        </h2>
        <p>
          {t(
            "Your happiness is our priority, which is why the team at Grand BanquetHall strives to offer exceptional service for birthday parties, quinceañeras, and more. We work with you to ensure our venue istransformed into the perfect location for your event."
          )}
          <br />
          <br />
          {t(
            "With a spacious dance floor, exquisite catering services, customizable décor options, and much more, our team will help you host a truly remarkable celebration. Nearly everything at our venue can be customized, helping you bring the party of your dreams to life!"
          )}
        </p>
      </div>

      <div className="container block-image-and-info">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block-info">
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Birthday")} <br /> {t("celebrations for all")}
              </h2>
              <p className="description">
                {t(
                  "Whether you’re celebrating your 50th birthday or a Sweet 16, our venue is perfect for whatever you need. Our versatile space and amenities allow us to accommodate any type of event. We offer above-and-beyond catering options, customizable event décor, and the best service in the industry. This makes Grand Banquet perfect for casual and formal events alike. No matter your party needs, we will exceed expectations and bring your vision to life."
                )}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <img alt="" src="/images/Birthday page/Birthday4.png" />
          </div>
        </div>
      </div>

      <div className="container enjoy-an-exceptional">
        <div className="mini-line light"></div>
        <h2 className="big-title">
          {t("Celebrate Quinceañeras And")} <br />{" "}
          {t("Birthday Parties In Style!")}
        </h2>
        <p>
          {t(
            "Grand Banquet Hall’s exquisite service, amenities, and accommodations are guaranteed to make quinceañeras or birthday celebrations a huge success. We even offer affordable options that fit just about any budget, making us one of the best party venues in Los Angeles. For more information on booking and availability, contact Grand Banquet Hall today!"
          )}
        </p>
      </div>
    </div>
  );
}

export default Birthday;

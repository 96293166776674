import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Contact.scss";
import { sendEmailQuery } from "../../reducers/emailSlice";
import MessasgeSuccess from "../nav-bars/book-now/MessasgeSuccess";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventsList, isLoad } = useSelector((state) => state.email);
  const [showSuccess, setShowSuccess] = useState(false);

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    guests_count: "",
    phone: "",
    email: "",
    message: "",
    date: "",
    event_name: "",
  });

  const onChangeHendler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const sendEmail = async (event) => {
    if (document.querySelector(".form-contact-us-page").checkValidity()) {
      event.preventDefault();
      dispatch(sendEmailQuery(form)).then(() => {
        setShowSuccess(true);
      });
    }
  };

  return (
    <div className="contact">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Dining/Catering Services1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <p className="site-name">{t("Contact")}</p>
        </div>
      </div>

      <div className="page-info">
        <div className="container">
          {!showSuccess ? (
            <form action="#" className="form-contact-us-page">
              <div className="row">
                <div className="col-lg-6">
                  <input
                    required
                    type="text"
                    className="form-control-plaintext"
                    placeholder={t("First Name*")}
                    name="first_name"
                    onChange={(event) => {
                      onChangeHendler(event);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    required
                    type="text"
                    className="form-control-plaintext"
                    placeholder={t("Last Name*")}
                    name="last_name"
                    onChange={(event) => {
                      onChangeHendler(event);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    required
                    type="text"
                    className="form-control-plaintext"
                    placeholder={t("Phone*")}
                    name="phone"
                    onChange={(event) => {
                      onChangeHendler(event);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    required
                    type="email"
                    className="form-control-plaintext"
                    placeholder={t("Mail*")}
                    name="email"
                    onChange={(event) => {
                      onChangeHendler(event);
                    }}
                  />
                </div>
                <div className="col-6 selected-section-input">
                  <div className="select-box">
                    <select
                      required
                      name="event_name"
                      onChange={(event) => {
                        onChangeHendler(event);
                      }}
                    >
                      <option>Please select your event*</option>
                      {eventsList.map((list, index) => (
                        <option key={index + 1}>{list}</option>
                      ))}
                    </select>
                    <i>&#8964;</i>
                  </div>
                </div>
                <div className="col-6 date-container">
                  <div className="select-box">
                    <input
                      required
                      type="number"
                      name="guests_count"
                      onChange={(event) => {
                        onChangeHendler(event);
                      }}
                      placeholder={t("Estimated Number of Guests*")}
                    />
                  </div>
                  <input
                    required
                    type="date"
                    className="date"
                    name="date"
                    onChange={(event) => {
                      onChangeHendler(event);
                    }}
                  />
                </div>
              </div>
              <div className="message-input">
                <textarea
                  required
                  name="message"
                  onChange={(event) => {
                    onChangeHendler(event);
                  }}
                  placeholder={t("Message*")}
                ></textarea>
              </div>
              <button
                type="submit"
                onClick={sendEmail}
                disabled={isLoad}
                className="btn btn-shadow dark"
              >
                {isLoad ? t("Sending...") : t("Send Message")}
              </button>
            </form>
          ) : (
            <MessasgeSuccess />
          )}

          <div className="row boxes">
            <div className="col-lg-4">
              <div className="box">
                <img alt="" src="/images/contact/Location.png" />
                <p className="title">Location</p>
                <p className="description">
                  6754 White Oak Ave. Van Nuys, <br /> Ca 91406
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box">
                <img alt="" src="/images/contact/Phone.png" />
                <p className="title">{t("Telephone")}</p>
                <p className="description">(818) 344-0500</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box">
                <img alt="" src="/images/contact/Email.png" />
                <p className="title">{t("Email")}</p>
                <p className="description">info@grandbanquetla.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

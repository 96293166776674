import { useDispatch, useSelector } from "react-redux";
import "./Slider.scss";
import {
  toggleLeft,
  toggleRight,
  closeSlider
} from "../../reducers/sliderSlice";
import { useEffect, useState } from "react";

function Slider() {
  const { slectedId, images } = useSelector((state) => state.slider);
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");

  useEffect(() => {
    let img = images.find((item) => item._id === slectedId);
    setSrc((prev) => `https://api.grandbanquetla.com${img.path}.png`);
  }, [slectedId]);

  return (
    <div className="SliderContent">
      <div className="slider">
        <span className="close-btn" onClick={() => dispatch(closeSlider())}>x</span>
        <img alt="" src={src} />
      </div>
      <span
        className="arrow left-arrow"
        onClick={() => {
          dispatch(toggleLeft());
        }}
      >
        <i style={{ fontStyle: 'inherit' }}>&#10094;</i>
      </span>
      <span
        className="arrow right-arrow"
        onClick={() => {
          dispatch(toggleRight());
        }}
      >
        <i style={{ fontStyle: 'inherit' }}>&#10095;</i>
      </span>
    </div>
  );
}

export default Slider;

import "./SocialMediaBar.scss";

function SocialMediaBar() {
  return (
    <div className="SocialEvents">
      <div className="event">
        <a href="https://www.facebook.com/GrandBanquet1" target="_blank">
          <i className="icon-fb"></i>
        </a>
      </div>
      <div className="event">
        <a href="https://www.instagram.com/grandbanquet" target="_blank">
          <i className="icon-instagram"></i>
        </a>
      </div>
      <div className="event">
        <a href="https://www.yelp.com/biz/grand-banquet-hall-van-nuys" target="_blank">
          <i className="icon-hill"></i>
        </a>
      </div>
    </div>
  );
}

export default SocialMediaBar;

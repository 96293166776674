import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const checkPassword = createAsyncThunk("password", async (password) => {
  return await axios.post("https://api.grandbanquetla.com/check-password", {
    password,
  });
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAdmin: false,
  },

  reducers: {
    reset: (state) => {
      return { ...state, isAdmin: false };
    },
  },

  extraReducers: {
    [checkPassword.pending]: (state, action) => {
      console.log("pending i mej ashxatuma?");
      return { ...state, isLoad: true };
    },
    [checkPassword.fulfilled]: (state, action) => {
      return { ...state, isAdmin: true, isLoad: false };
    },
    [checkPassword.rejected]: (state, action) => {
      console.log("rejected i mej ashxatuma?");
      return { ...state, isAdmin: false, isLoad: false };
    },
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;

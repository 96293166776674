import "./Image.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { removeImageQuery } from "../../reducers/galleryImagesReducer";
import { useDispatch, useSelector } from "react-redux";
import { openSlider } from "../../reducers/sliderSlice";

function CustomImage({ src, id, isAdmin }) {
  let [isLoad, setIsload] = useState(false);
  
  const { isRemov, images } = useSelector((state) => state.galleryImage);

  const { isOpen } = useSelector((state) => state.slider);

  const dispatch = useDispatch();
  useEffect(() => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      setIsload(true);
    };
    img.src = src;
  }, []);

  return (
    <>
      {isAdmin && (
        <div className="action-buttons">
          <button
            className="bg-danger"
            onClick={() => dispatch(removeImageQuery(id))}
          >
            {isRemov ? "Deleting..." : "DELETE"}
          </button>
        </div>
      )}

      {src && (
        <span
          className="image"
          style={{ backgroundImage: `url(${src})` }}
          onClick={() => {dispatch(openSlider({id, src, images}))}}
        >
          <span className={isLoad ? "skeleton hide" : "skeleton"}></span>
        </span>
      )}
    </>
  );
}

export default CustomImage;

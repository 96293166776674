import "./Gallery.scss";
import "react-loading-skeleton/dist/skeleton.css";
import CustomImage from "../image/CustomImage";
import UpdateImage from "../image/UpdateImage";
import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGalleryImages } from "../../reducers/galleryImagesReducer";
import { useTranslation } from "react-i18next";
import Slider from "../slider/Slider";

function Gallery() {
  const { t } = useTranslation();
  const { isAdmin } = useSelector((state) => state.auth);

  const { images } = useSelector((state) => state.galleryImage);

  const { isOpen } = useSelector((state) => state.slider);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGalleryImages());
  }, []);

  return (
    <div className="Gallery">
      {isOpen ? <Slider /> : <Fragment />}
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Gallery/Gallery1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <h1 className="site-name">{t("Our Gallery")}</h1>
        </div>
      </div>
      <div className="container page-photos">
        <div className="boxes">
          {isAdmin ? (
            <div className="box">
              <UpdateImage />
            </div>
          ) : (
            <></>
          )}
          {images &&
            images.map((image, index) => {
              return (
                <div className="box" key={image._id}>
                  <CustomImage
                    id={image._id}
                    isAdmin={isAdmin}
                    src={"https://api.grandbanquetla.com" + image.path + ".png"}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Gallery;

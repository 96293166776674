const styleP = {
  color: '#969696',
  fontSize: '18px'
}

function MessasgeSuccess() {
  return (
    <div className="container enjoy-an-exceptional" style={{textAlign: "center"}}>
      <div className="mini-line light"></div>
      <h2 className="big-title">Thank You</h2>

      <p style={styleP}>
        Thank You THANK YOU FOR SUBMITTING AN INQUIRY OF OUR VENUE AND SERVICES.
        ONE OF OUR TEAM MEMBERS WILL CONTACT YOU WITHIN 24 HOURS.
      </p>
      <p style={styleP}>For urgent questions, you may also contact us at (818) 344-0500</p>
      <p style={styleP}>We look forward to speaking with you soon!</p>
    </div>
  );
}

export default MessasgeSuccess;

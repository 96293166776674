import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reset, checkPassword } from "../../reducers/authSlice";
import Gallery from "../gallery/Gallery";

function Admin() {
  let password = prompt("Please write passsword");
  const dispatch = useDispatch();

  useEffect(() => {
    if (password.trim()) {
      dispatch(checkPassword(password))
    }
  }, [password]);

  useEffect(() => {
    return () => {
      dispatch(reset())
    };
  }, []);

  return (
    <>
      <Gallery />
    </>
  );
}

export default Admin;

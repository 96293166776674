import Footer from "./components/layouts/footer/Footer";
import Header from "./components/layouts/header/Header";
import BookNowForm from "./components/nav-bars/book-now/BookNowForm";
import SocialMediaBar from "./components/nav-bars/social-bar/SocialMediaBar";
import { useRoutes } from "./routes";
import ScrollToTop from "./components/scroll/ScrollToTop";

function App() {
  const routes = useRoutes();
  return (
    <div className="App">
      <ScrollToTop />
      <SocialMediaBar />
      <BookNowForm />
      <Header />
      {routes}
      <Footer />
    </div>
  );
}

export default App;

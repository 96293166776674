import React from "react";
import "./Venue.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Venue() {
  const { t } = useTranslation();

  return (
    <div className="Venue">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Venue/Venue 1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <h1 className="site-name">{t("Our Venue")}</h1>
          <p>{t("World-Class Service & Amenities")}</p>
        </div>
      </div>
      <div className="container page-info">
        <p>
          {t(
            "Your special event deserves nothing but the best, and Grand Banquet Hall is here to help. As soon as you step into our event venue, you’ll be welcomed with world-class service and breathtaking décor. By combining expert service with superior accommodations and amenities, Grand Banquet Hall has established a reputation as one of Los Angeles’ best event venues."
          )}
          <br />
          <br />
          {t(
            "Grand Banquet Hall has years of experience hosting social or corporate events of all sizes and is ready to serve your needs with ease."
          )}
        </p>
      </div>
      <div className="an-event-venue">
        <span className="layer"></span>
        <img alt="" src="/images/Venue/Venue 2.png" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="mini-line"></span>
              <h2>{t("An Event Venue For Any Occasion")}</h2>
              <p>
                {t(
                  "Known for its spacious and glamorous ballroom, Grand Banquet Hall is capable of hosting a wide variety of events with accommodations to up to 300 guests! Some of our most common events held at the luxury banquet hall include"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="circles-row">
          <div className="flex-nowrap circles">
            <div className="circle-box">
              <span>
                {t("Wedding")} <br /> {t("receptions")}
              </span>
            </div>
            <div className="circle-box">
              <span>
                {t("Anniversary")} <br /> {t("parties")}
              </span>
            </div>
            <div className="circle-box">
              <span>
                {t("Bridal &Baby")} <br /> {t("Showers")}
              </span>
            </div>
            <div className="circle-box">
              <span>
                {t("Corporate")} <br /> {t("events")}
              </span>
            </div>
            <div className="circle-box">
              <span>{t("Quinceaneras")}</span>
            </div>
            <div className="circle-box">
              <span>
                {t("Birthday")} <br /> {t("parties")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid superior-catering">
        <div className="row">
          <div className="col-md-4 col-xl-6 img-box d-none d-md-block">
            <img alt="" src="/images/Venue/Venue 3.png" />
          </div>
          <div className="col-12 col-md-8 col-xl-6 content">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">Impeccable Service</h2>
              <p className="info">
                {t(
                  "At Grand Banquet Hall, our team strives to treat you and your guests with the utmost level of respect and class. Our event venue staff is ready, willing, and able to work with you to make your wedding, corporate event, or social event a huge hit."
                )}
                <br />
                <br />
                {t(
                  "Your happiness is our top priority, and Grand Banquet Hall’s expert service puts us a step above other party venues and event venues in Los Angeles. You definitely won’t be disappointed by the exquisite service at Grand Banquet Hall."
                )}
              </p>
            </div>
            <div>
              <Link to="gallery">
                <span className="btn btn-transparent btn-shadow">
                  {t("photo gallery")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container block-image-and-info">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block-info">
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Customize the")} <br /> {t("venue to your linking")}
              </h2>
              <p className="description">
                {t(
                  "Have you envisioned every detail of your desired event? Whether it’s a wedding reception, quinceanera or any other special event, it’s likely you have a clear vision of what you want and Grand Banquet Hall is here to make that vision a reality!"
                )}
                <br />
                {t(
                  "To ensure your banquet hall experience lives up to your dreams, the team at Grand Banquet Hall will help you customize our event venue to your liking. We offer a variety of event décor, seating arrangement options, and many more details that go into planning your perfect event! Grand Banquet Hall"
                )}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <img alt="" src="/images/Venue/Venue 4.png" />
          </div>
        </div>
      </div>

      <div className="container enjoy-an-exceptional">
        <div className="mini-line light"></div>
        <h2 className="big-title">{t("An Event Venue Like No Other")}</h2>
        <p>
          {t(
            "With unparalleled service, amenities, and accommodations, Grand Banquet Hall is truly one of the best event venues in the Los Angeles area. If you’re looking for a world-class event venue or party venue in Los Angeles, contact Grand Banquet Hall today."
          )}
        </p>
      </div>
    </div>
  );
}

export default Venue;

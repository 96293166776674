import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./components/home/Home";
import Weddings from "./components/weddings/Weddings";
import CorporateEvents from "./components/corporate-events/CorporateEvents";
import Birthday from "./components/birthday/Birthday";
import Venue from "./components/venue/Venue";
import Gallery from "./components/gallery/Gallery";
import Dining from "./components/dining/Dining";
import Contact from "./components/contact/Contact";
import Admin from "./components/admin/Admin";

export const useRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/weddings" exact>
        <Weddings />
      </Route>
      <Route path="/corporate-events" exact>
        <CorporateEvents />
      </Route> 
      <Route path="/birthday" exact>
        <Birthday />
      </Route>
      <Route path="/venue" exact>
        <Venue />
      </Route>
      <Route path="/gallery" exact>
        <Gallery />
      </Route>
      <Route path="/dining" exact>
        <Dining />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
      <Route path="/admin" exact>
        <Admin />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, NavLink, Link } from "react-router-dom";
import i18n from "../../../i18n";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavMenu } from "../../../reducers/configSlice";
import { useTranslation } from "react-i18next";
import useMobile from "../../../hooks/useMobile";



function Header() {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [isActive, setIsActive] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [eventName] = useState(isMobile? 'click' : 'mouseover');

  const dispatch = useDispatch();
  const { navMenuOpen } = useSelector((state) => state.config);
  const eventRef = useRef();

  const toggleEvent = useCallback(() => {
    setEventsOpen(prev => !prev);
  }, [])

  
  const changeLanguage = (lng) => {
    localStorage.setItem("Language", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if(eventName === 'mouseover') {
      // eventRef.current.addEventListener('mouseleave', () => setEventsOpen(prev => false))
    }
    // eventRef.current.addEventListener(eventName, toggleEvent)
    window.addEventListener("scroll", handleScroll);

    return () => {
      eventRef.current.removeEventListener(eventName, toggleEvent);
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const test = () => {
    console.log("here");
  };

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsActive((prev) => true);
    } else {
      setIsActive((prev) => false);
    }
  };

  return (
    <header className={isActive ? "Header active" : "Header"}>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link to="/">
            <span className="logo">
              <i className="icon-logo"></i>
            </span>
          </Link>
          <Link to="/contact">
            <span
              className="book-now d-none"
              style={isActive ? { color: "white" } : {}}
            >
              Book Now
            </span>
          </Link>
          <button
            className="navbar-toggler"
            // type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="true"
            onClick={() => {
              dispatch(toggleNavMenu(!navMenuOpen));
            }}
            aria-label="Toggle navigation"
          >
            <span
              className={navMenuOpen ? "line active" : "line"}
              style={isActive ? { borderColor: "white" } : {}}
            ></span>
            <span
              className={navMenuOpen ? "line active" : "line"}
              style={isActive ? { borderColor: "white" } : {}}
            ></span>
            <span
              className={navMenuOpen ? "line active" : "line"}
              style={isActive ? { borderColor: "white" } : {}}
            ></span>
          </button>
          <div
            className={
              navMenuOpen
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <span className="navbar-nav me-auto mb-2 mb-lg-0"></span>
            <div className="right-section">
              <ul className="d-flex">
                <li>
                  <NavLink activeClassName="is-active" exact={true} to="/">
                    {t("Home")}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="is-active" to="/venue">
                    {t("Venue")}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="is-active" to="/gallery">
                    {t("Gallery")}
                  </NavLink>
                </li>
                <li
                  className={
                    eventsOpen
                      ? "custom-dropdown-menu active"
                      : "custom-dropdown-menu"
                  }
                  ref={eventRef}
              
                  // onMouseEnter={() => console.log("sssssssssssssssssssssssssssssss")}
                  // onClick={() => setEventsOpen(!eventsOpen)}
                >
                  <a className="dropdown-title">{t("Events")}</a>
                  <div className="dropdown-menu-lists">
                    <div className="dropdown-menu-item">
                      <NavLink activeClassName="is-active" to="weddings">
                        {t("Weddings")}
                      </NavLink>
                    </div>
                    <div className="dropdown-menu-item">
                      <NavLink activeClassName="is-active" to="birthday">
                        {t("Birthdays & Quinceaneras")}
                      </NavLink>
                    </div>
                    <div className="dropdown-menu-item">
                      <NavLink
                        activeClassName="is-active"
                        to="corporate-events"
                      >
                        {t("Corporate Events")}
                      </NavLink>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink activeClassName="is-active" to="/dining">
                    {t("Dining")}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="is-active" to="/contact">
                    {t("Contact")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;

import { useState } from "react";
import "./Dining.scss";
import CateringCard from "./CateringCard";
import { useTranslation } from "react-i18next";

function Dining() {
  const { t } = useTranslation();
  const obj = [
    {
      isActive: true,
      url: "/images/Dining/Catering Services3.png",
      title: t("Buffet Style"),
      description: t(
        `A delicious buffet spread of entrees and hors d’oeuvres is guaranteed to satisfy your guests. Grand Banquet Hall’s spacious event venue is able to handle several buffet catering stations with ease, allowing your guests to craft their own plates with as many options as they’d like.`
      ),
    },
    {
      isActive: false,
      url: "/images/Dining/Catering Services4.png",
      title: t("Family Style"),
      description: t(
        `For a more intimate event catering experience, consider Grand Banquet Hall’s family style catering. From delicious appetizers to mouth-watering entrees, our staff will serve your guests right at their tables, allowing them to enjoy an array of dishes without ever leaving their seats.`
      ),
    },
    {
      isActive: false,
      url: "/images/Dining/Catering Services7.png",
      title: t("Take-Out Catering"),
      description: t(
        `Grand Banquet Hall’s in-house catering company, Angel’s International Catering, can provide delicious dining for any event, regardless of location. Thanks to our take-out catering options, you’ll be able to have superior catering at private and personal events, bringing a taste of Grand Banquet Hall to guests wherever you are.`
      ),
    },
  ];

  const [selectedCard, setCard] = useState(obj[0]);

  return (
    <div className="Dining">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Dining/Catering Services1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <p className="site-name">{t("Catering Services")}</p>
        </div>
      </div>
      <div className="container page-info">
        <p>
          {t(
            "Your special event deserves truly delicious dining. Grand Banquet Hall offers the best catering services in Los Angeles, with a huge selection of menu options and dining styles fit for any type of event. Our catering team will work with you to create a menu that perfectly fits your desires, from authentic Mediterranean cuisine to American favorites. Grand Banquet Hall’s exceptional event catering services are guaranteed to make your event a huge success."
          )}
          <br />
          <br />
          {t(
            "Guests expect nothing but the best during your wedding day. And Grand Banquet Hall’s exceptional wedding catering service is guaranteed to impress. We offer some of the best food and catering in Los Angeles, with truly delectable wedding food options."
          )}
        </p>
      </div>
      <div className="categories-block">
        <div className="full-block-image">
          <img alt="" src="/images/Dining/Catering Services2.png" />
        </div>
        <div className="container categories">
          <div className="row flex-nowrap">
            {obj.map((card, index) => {
              return (
                <div
                  key={index}
                  className={
                    card.title === selectedCard.title ? "box active" : "box"
                  }
                  onClick={() => setCard(card)}
                >
                  <i className="icon-bufet-style"></i>
                  <p>{card.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <CateringCard card={selectedCard}></CateringCard>

      <div className="container enjoy-an-exceptional">
        <div className="mini-line"></div>
        <h2 className="big-title">Catering Services Guaranteed To Impress!</h2>
        <p>
          {t(
            "Grand Banquet Hall doesn’t skimp on quality when it comes to our catering options. We offer delicious h’or d’oeuvres and appetizing entrees prepared to your liking, with a variety of catered dining options perfect for any type of event. For more information on our event catering services, contact Grand Banquet Hall today."
          )}
        </p>
      </div>
    </div>
  );
}

export default Dining;

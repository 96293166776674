import { useState } from "react";
import "./UpdateImage.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../reducers/galleryImagesReducer";

function UpdateImage() {
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("uploaded_file", selectedFile, selectedFile.name);
    dispatch(uploadImage(formData));
  };

  return (
    <div className="upload-box">
      <input type="file" onChange={onFileChange} />
      <span className="add-button" onClick={onFileUpload}>
        +
      </span>
    </div>
  );
}

export default UpdateImage;

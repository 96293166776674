import { createSlice } from "@reduxjs/toolkit";
// import store from "./store";

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    isOpen: false,
    slectedId: null,
    images: null,
  },

  reducers: {
    openSlider: (state, action) => {
      return {
        ...state,
        isOpen: !state.isOpen,
        slectedId: action.payload.id,
        images: action.payload.images,
      };
    },
    toggleRight: (state, action) => {

      let x = 0;
      state.images.map((img, index) => {
        if (img._id === state.slectedId) {
          if (index < state.images.length) {
            x = index + 1;
          }
        }
      });
      return { ...state, slectedId: state.images[x]._id };
    },
    toggleLeft: (state, action) => {
      console.log("left");
      let x = 0;
      state.images.map((img, index) => {
        if (img._id === state.slectedId) {
          if (index > 0) {
            x = index - 1;
          }
        }
      });
      return { ...state, slectedId: state.images[x]._id };
    },
    closeSlider: (state, action) => {
      return {
        ...state,
        isOpen: !state.isOpen,
        slectedId: null,
        images: null,
      };
    },
  },
});

export const { openSlider, toggleLeft, toggleRight, closeSlider } =
  sliderSlice.actions;
export default sliderSlice.reducer;

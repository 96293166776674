import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EventCardInfo({ card }) {
  const { t } = useTranslation();
  return (
    <div className="container event-info">
      <div className="row">
        <div className="d-none d-md-block col-lg-6 img-block">
          <img alt="" src={card.url} />
        </div>
        <div className="col-12 col-lg-6 content">
          <div>
            <span className="mini-line"></span>
            <h2 className="big-title">{card.card_title}</h2>
            <p>{card.description}</p>
          </div>
          <div className="d-flex">
            <Link to="gallery">
              <span className="btn btn-shadow dark btn-transparent">
                {t("photo gallery")}
              </span>
            </Link>
            <Link to="venue">
              <span className="btn btn-shadow dark">{t("explore the venue")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCardInfo;

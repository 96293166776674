import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Weddings.scss";

function Weddings() {
  const { t } = useTranslation();
  return (
    <div className="Weddings">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Weddings page/Weddings1.png" />
        <div className="container">
          <span className="mini-line"></span>
          <p className="site-name">
            {t("Incredible")} <br /> {t("Weddings")}
          </p>
        </div>
      </div>

      <div className="container page-info">
        <p>
          {t(
            "A wedding day is considered to be one of the most profound experiences in a lifetime. At Grand Banquet Hall, we understand this importance, and we’re here to make your dream wedding into reality."
          )}
          <br />
          <br />
          {t(
            "Grand Banquet Hall is one of the most renowned wedding venues in Los Angeles because of its prestigious location, exquisite décor, spacious accommodations, and outstanding catering services. With all the delights we have to offer, your wedding reception or wedding ceremony will be among the most cherished memories in your lifetime."
          )}
        </p>
      </div>

      <div className="container customize-our-venue">
        <div className="row">
          <div className="col-12 col-lg-6 info">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Customize Our Venue")} <br />
                {t(" Specifically For")} <br /> {t("Your Wedding")}
              </h2>
              <p>
                {t(
                  "Our team at Grand Banquet Hall considers your satisfaction our top priority. This is why we offer numerous customizable amenities for your wedding, tailoring our venue to your every need so you can have the dream wedding you’ve been waiting for."
                )}
                <br />
                <br />
                {t(
                  "We offer a wide range of options when it comes to wedding decorations, reception hall layouts, catering menus, and much more. Nearly everything that our wedding venue offers is customizable. Our wedding experts will even coordinate with your third-party event vendors to ensure your big day goes smoothly."
                )}
              </p>
            </div>
            <div>
              <Link to="venue">
                <span className="btn btn-shadow dark">
                  {t("explore the venue")}
                </span>
              </Link>
              <Link to="gallery">
                <span className="btn btn-shadow dark btn-transparent">
                  {t("photo gallery")}
                </span>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block img-block">
            <img alt="" src="/images/Weddings page/Weddings2.png" />
          </div>
        </div>
      </div>

      <div className="container-fluid superior-catering">
        <div className="row">
          <div className="col-md-4 col-xl-6 img-box d-none d-md-block">
            <img alt="" src="/images/Weddings page/Weddings3.png" />
          </div>
          <div className="col-12 col-md-8 col-xl-6 content">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("Superior Catering ")}
                <br /> {t("Services For Weddings")}
              </h2>
              <p className="info">
                {t(
                  "Your guests deserve nothing but the best catering at your wedding reception, and this is why we at Grand Banquet Hall work with the best in the industry. We are proud to offer phenomenal wedding catering services. We’ll work with you to create the perfect wedding reception menu, with signature family style options, hors d’oeuvres plates, and buffet dining. Our catering is guaranteed to impress and delight your guests."
                )}
              </p>
            </div>
            <div>
              <span className="btn btn-transparent btn-shadow">
                {t("Dining & Catering Options")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container block-image-and-info">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block-info">
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("The wedding venue")} <br /> {t("of your dreams")}
              </h2>
              <p className="description">
                {t(
                  "With a striking combination of stunning décor, exquisite amenities, delicious catering, and superior service, Grand Banquet Hall is one of the finest wedding venues in Los Angeles. We provide a spacious and stunning location for your wedding reception or wedding ceremony, allowing you to customize our venue however you’d like! We also provide impeccable wedding services and affordable options to match any budget. For more information on booking and availability, contact our team at Grand Banquet Hall today!"
                )}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <img alt="" src="/images/Weddings page/Weddings4.png" />
          </div>
        </div>
      </div>
      <div className="container the-wedding-venue">
        <div className="mini-line"></div>
        <h2 className="big-title">
          {t("The Wedding Venue Of")} <br /> {t("Your Dreams")}
        </h2>
        <p>
          {t(
            "With a striking combination of stunning décor, exquisite amenities, delicious catering, and superior service, Grand Banquet Hall is one of the finest wedding venues in Los Angeles."
          )}
          <br />
          <br />
          {t(
            "We provide a spacious and stunning location for your wedding reception or wedding ceremony, allowing you to customize our venue however you’d like! We also provide impeccable wedding services and affordable options to match any budget. For more information on booking and availability, contact our team at Grand Banquet Hall today!"
          )}
        </p>
      </div>
    </div>
  );
}

export default Weddings;

import "./Home.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Home() {
  const {t } = useTranslation();
  return (
    <div className="Home">
      <div className="top-image-block">
        <span className="layer"></span>
        <img alt="" src="/images/Home page/Home page1.png" />
        <div className="container">
          <p className="welcome">{t("Welcome To")}</p>
          <p className="site-name">{t("Grand Banquet Hall")}</p>
        </div>
      </div>
      <div className="event-items">
        <div className="items d-flex">
          <div className="event-item">
            <div className="event-box">
              <div className="content">
                <div className="layer">
                  <img alt="" src="images/Home page/Home page3.png" />
                  <div className="title">{t("Weddings")}</div>
                </div>
                <Link to="/weddings">
                  <div className="info">
                    <p className="description">
                      {t(
                        "Your wedding day is a once in a lifetime occasion, cherished forever by you and your spouse."
                      )}
                      <br />
                      <br />
                      {t(
                        "Our banquet hall’s exceptional amenities, world-class service, and flawless décor will make the wedding of your dreams a reality."
                      )}
                    </p>
                    <span className="more">{"more ->"}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="event-item">
            <div className="event-box">
              <div className="content">
                <div className="layer">
                  <img alt="" src="images/Home page/Home page 2.png" />
                  <div className="title">{t("Social Events")}</div>
                </div>
                <Link to="/birthday">
                  <div className="info">
                    <p className="description">
                      {t(
                        "Whatever your special occasion may be, Grand banquet hall knows how to help you celebrate."
                      )}
                      <br />
                      <br />
                      {t("Our spacious banquet hall hosts a wide variety of social events, including baby and bridal showers, birthday parties, quinceañeras, anniversaries, graduation parties, bar/bat mitzvahs, and more.")}
                    </p>
                    <span className="more">{"more ->"}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="event-item">
            <div className="event-box">
              <div className="content">
                <div className="layer">
                  <img alt="" src="images/Home page/Home page4.png" />
                  <div className="title">{t("Corporate Events")}</div>
                </div>
                <Link to="/corporate-events">
                  <div className="info">
                    <p className="description">
                      {t("Your corporate event deserves a banquet hall that matches your company’s or organizations sterling professional image. Our professional accommodations are perfectly suited for corporate conferences, presentations, holiday parties, fundraising galas, awards banquets, retirement parties, and so much more.")}
                    </p>
                    <span className="more">{"more ->"}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container description-block">
        <p>{t("Our philosophy at Grand Banquet Hall is that life’s big moments deserve to be well celebrated with the ones you love, creating memories that will last a lifetime. Whether the big moment you’re celebrating is a a birthday, a quinceañera, or a corporate event, at Grand Banquet Hall, we will do our part to ensure your event exceeds your expectations.")}
        </p>
      </div>
      <div className="menu-boxes">
        <div className="d-flex">
          <div className="item">
            <Link to="/venue">
              <div className="menu-box">
                <i className="icon-venue"></i>
                <p>{t("Venue")}</p>
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/dining">
              <div className="menu-box second-box">
                <i className="icon-fine-dining"></i>
                <p>{t("Fine Dining")}</p>
              </div>
            </Link>
          </div>
          <div className="item">
            <div className="menu-box">
              <i className="icon-great-service"></i>
              <p>{t("Great Service")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid start-planning">
        <div className="row">
          <div className="col-0 col-md-4 col-xl-6 img-box d-none d-md-block">
            <img alt="" src="/images/Home page/Home page5.png" />
          </div>
          <div className="col-12 col-md-8 col-xl-6 content">
            <div>
              <span className="mini-line"></span>
              <h2 className="big-title">{t("Start Planning Your Event")}</h2>
              <p className="info">
                {t("Start selecting your desire date by filling out this simple booking inquiry form and be one step closer to having your special event at the beautiful Grand Banquet Hall.")}
              </p>
              <p className="phone">(818) 344-0500</p>
            </div>
            {/* <div>
              <span className="btn btn-transparent btn-shadow">
                {t("price & availability")}
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container block-image-and-info">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block-info">
              <span className="mini-line"></span>
              <h2 className="big-title">
                {t("A Venue Perfect")} <br /> {t("For All Types")} <br /> {t("Of Social Events")}
              </h2>
              <p className="description">
                {t("Few social event venues in Los Angeles can offer the combination of exceptional service, accommodations, and amenities presented by Grand Banquet Hall. We even offer affordable service that will fit almost any budget. For more info on our social event venue services, contact Grand Banquet Hall today.")}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <img alt="" src="/images/Home page/Home page 6.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

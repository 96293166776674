import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const sendEmailQuery = createAsyncThunk("send", async (data) => {
  return await axios({
    url: "https://api.grandbanquetla.com/send-mail",
    method: "POST",
    headers: {},
    data,
  });
});

const emailSlice = createSlice({
  name: "email",
  initialState: {
    eventsList: [
      "Anniversary",
      "Birthday",
      "Corporate Event",
      "Christening",
      "Engagement",
      "Fashion",
      "Fundraiser",
      "Graduation",
      "Luncheon",
      "Prom",
      "Quinceañera",
      "Wedding",
      "Other",
    ],
    isLoad: false,
  },

  extraReducers: {
    [sendEmailQuery.pending]: (state, action) => {
      return { ...state, isLoad: true };
    },
    [sendEmailQuery.fulfilled]: (state, action) => {
      return {...state, isLoad: false};
    },
    [sendEmailQuery.rejected]: (state, action) => {
      console.log("reject", action);
    },
  },
});

export const { increment, decrement, incrementByAmount } = emailSlice.actions;
export default emailSlice.reducer;

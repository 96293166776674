import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-lg d-flex align-items-center">
            <span className="logo">
              <i className="icon-logo"></i>
            </span>
          </div>
          <div className="col-lg d-flex flex-column justify-content-between">
            <p className="address">
              6754 WHITE OAK AVE <br /> VAN NUYS, CA 91406
            </p>
            <p className="phone">(818) 344-0500</p>
            <div className="d-flex social-media">
              <a href="https://www.facebook.com/GrandBanquet1" target="_blank">
                <i className="icon-fb"></i>
              </a>
              <a href="https://www.instagram.com/grandbanquet" target="_blank">
                <i className="icon-instagram"></i>
              </a>
              <a
                href="https://www.yelp.com/biz/grand-banquet-hall-van-nuys"
                target="_blank"
              >
                <i className="icon-hill"></i>
              </a>
            </div>
          </div>
          <div className="col-lg d-flex flex-column justify-content-between">
            <div>
              <Link to="venue">{t("Our Venue")}</Link>
            </div>
            <div>
              <Link to="gallery">{t("Photo Gallery")}</Link>
            </div>
            <div>
              <Link to="dining">{t("Dining & Catering")}</Link>
            </div>
            <div>
              <Link to="contact">{t("Contact us")}</Link>
            </div>
          </div>
          <div className="col-lg d-flex flex-column justify-content-between">
            <div>
              <Link to="weddings">{t("Weddings")}</Link>
            </div>
            <div>
              <Link to="birthday">{t("Birthday")}</Link>
            </div>
            <div>
              <Link to="corporate-events">{t("Corporate events")}</Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    navMenuOpen: false,
  },


  reducers: {
    toggleNavMenu: (state, action) => {
      return {...state, navMenuOpen: action.payload};
    }
  }
});

export const { toggleNavMenu } = configSlice.actions;
export default configSlice.reducer;
